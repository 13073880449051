@tailwind base;
@tailwind components;
.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 4px 8px;
  margin-top: -42px;
  position: absolute;
  z-index: 100;
}
.tooltip .tooltip-pointer {
  visibility: hidden;
  margin-top: -24px;
  margin-right: 16px;
  position: absolute;
}
.tooltip:hover .tooltip-text,
.tooltip:hover .tooltip-pointer {
  visibility: visible;
}
.loader {
  border-top-color: currentColor;
  animation: spinner 0.8s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__day--selected {
  @apply bg-primary-300 !important;
}

.input {
  @apply shadow-sm focus:ring-primary-300 focus:border-primary-300 block sm:text-sm border-gray-400 rounded-md;
}
.btn {
  @apply py-2 px-4 inline-flex md:w-auto flex-grow-0 flex-shrink-0 border border-transparent justify-center items-center disabled:opacity-50 uppercase text-sm font-medium rounded-md text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.section-border {
  @apply bg-white rounded-lg border border-gray-300;
}
@tailwind utilities;
