.logo {
  animation: circle-animation 2s infinite linear;
  opacity: 1;
  transform-origin: center;
}

@keyframes circle-animation {
  50% {
    opacity: 0;
    transform: scale(1.2);
  }
}
